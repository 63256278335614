import React from "react";

export default function Desktop({ common: { data } }) {
  return (
    <div className="layout-qr desktop">
      <div className="excerpt2">
        Estás a un paso de obtener tu dinero de inmediato
      </div>
       <hr />
      <div className="excerpt3">
        Llámanos y conoce nuestras opciones de crédito:
      </div>
      <div className="phone">{data.phoneMask2}</div>
      <div className="grid-wrapper">
        <div className="qr-wrapper">
          <img src={data.qrAlternative} alt="QR" />
        </div>

        <div className="txt-wrapper">
          <div>
            También puedes realizar tu solicitud con uno de nuestros socios:
          </div>
          <div className="img-wrapper">
            <img src={data.logoVertical} alt="Logo" />
          </div>
          <div>
            Escanea el código QR y descarga la app para continuar con el proceso
            desde tu móvil
          </div>
        </div>
      </div>
    </div>
  );
}
