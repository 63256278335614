import logoVertical from "./images/logo_vertical.svg";
import logoHorizontal from "./images/logo_horizontal.svg";
import logoApp from "./images/app_icon.svg";
import logoAppSmall from "./images/logo-baz-peq.png";

import qrApproved from "./images/qr_approved.png";
import qrRejected from "./images/qr_rejected.png";
import qrAlternative from "./images/qr_alternative.png";

export default [
  {
    criteria: "common",
    data: {
      logoVertical,
      logoHorizontal,
      logoApp,
      logoAppSmall,
      phone: "+(33)2100-6731",
      phoneMask: "(33) 2100 - 6731",
      phone2: "+(55)2601-4222",
      phoneMask2: "(55) 2601 - 4222",
      appApproved: "https://bancoazteca.onelink.me/F2Vv/4a933f40",
      appRejected: "https://bancoazteca.onelink.me/F2Vv/1de8fcb6",
      appAlternative: "https://bancoazteca.onelink.me/F2Vv/fd5aeec2",
      qrApproved,
      qrRejected,
      qrAlternative,
    },
  },
  {
    criteria: 2.2,
    data: {
      h1: "Gracias por confiar en nosotros",
    },
  },
  {
    criteria: 2.3,
    data: {
      h1: "Actualmente no será posible aprobar tu solicitud",
    },
  },
  {
    criteria: 3.1,
    data: {
      h1: "Nosotros te decimos que sí",
    },
  },
  {
    criteria: 3.2,
    data: {
      h1: "Nosotros te decimos que sí",
    },
  },
  {
    criteria: 8.1,
    data: {
      h1: "¡Bienvenido!",
      h3: "Actualmente estamos trabajando en tu solicitud.",
    },
  },
  {
    criteria: 8.2,
    data: {
      h3: "¡Nos alegra tenerte de vuelta! Inicia tu solicitud ahora",
    },
  },
  {
    criteria: 9.1,
    data: {
      h3:
        "¡Hola! Ya hemos recibido tu solicitud y hemos evaluado tu opción de crédito",
    },
  },
  {
    criteria: 9.3,
    data: {
      h1: "¡Hola! Por el momento no podemos otorgarte un préstamo...",
    },
  },
  {
    criteria: 10.1,
    data: {
      h1: "¡Nos alegra tenerte de vuelta!",
      h2: "Continúa con tu solicitud",
      h3: "Cada vez estás más cerca de obtener tu crédito",
    },
  },
  {
    criteria: 10.2,
    data: {
      h1: "¡Nos alegra tenerte de vuelta!",
      h2: "Contáctanos para resolver todas tus dudas",
      h3: "Cada vez estás más cerca de obtener tu crédito",
    },
  },
  {
    criteria: 10.3,
    data: {
      h1: "Recuerda que siempre tenemos las mejores ofertas de crédito para ti",
    },
  },
  {
    criteria: 10.4,
    data: {
      h1:
        "¡Hola! Lamentablemente por el momento no podemos otorgarte un préstamo",
    },
  },
];
